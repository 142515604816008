import React, { useState, useContext } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import ModeContext from "./ModeContext";
import { useMutation } from "@tanstack/react-query";
import { CircularProgress, Fade } from "@material-ui/core";

let store = require("./store.json");

const useStyles = makeStyles({
  gridItem: {
    backgroundColor: "#60489D",
    color: "white",
    "&:hover": {
      backgroundColor: "#60489D !important",
    },
    borderTop: "3px solid white",
    borderBottom: "3px solid white",
    borderLeft: "1.5px solid white",
    borderRight: "1.5px solid white",
    height: "80px",
    fontSize: "30px",
    cursor: "pointer",
    lineHeight: "60px",
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-webkit-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
  },
  gridItemDisabled: {
    backgroundColor: "#60489D",
    color: "white",
    "&:hover": {
      backgroundColor: "#60489D !important",
    },
    borderTop: "3px solid white",
    borderBottom: "3px solid white",
    borderLeft: "1.5px solid white",
    borderRight: "1.5px solid white",
    height: "80px",
    fontSize: "30px",
    cursor: "not-allowed",
    lineHeight: "60px",
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-webkit-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
  },
  pin: {
    textAlign: "center",
    fontSize: "36px",
    letterSpacing: "16px",
    fontWeight: "bold",
    height: "40px",
  },
  pinMessage: {
    paddingBottom: "1rem",
  },
});

const CheckPin = (props) => {
  const { empid } = props;
  const classes = useStyles();
  const { mode, setMode } = useContext(ModeContext);
  const [entered, setEntered] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [messageError, setMessageError] = useState("");
  const [timerId, setTimerId] = useState("");

  const mutation = useMutation({
    mutationFn: () => {
      return axios
        .post("https://williamsonsfoodworks.com.au/api/post_employees_timeclock.php", { store: store.id, empid: empid, mode: mode, date_time: SqlDatetime() })
        .then((result) => {
          if (result.data.error) {
            setMessageError(
              <Alert severity="error" style={{ justifyContent: "center" }}>
                <strong>{result.data.message}</strong>
              </Alert>
            );
            const timer = () =>
              setTimeout(() => {
                setMessageError("");
              }, 3000);
            setTimerId(timer);
          } else {
            setMessageSuccess(
              <Alert severity="success" style={{ justifyContent: "center" }}>
                <strong>{result.data.message}</strong>
              </Alert>
            );
            const timer = () =>
              setTimeout(() => {
                setMessageSuccess("");
                setEntered("");
                setMode(undefined);
              }, 3000);
            setTimerId(timer);
          }
        })
        .catch((error) => {
          setMessageError(
            <Alert severity="error" style={{ justifyContent: "center" }}>
              <strong>An error occured, Please try again!</strong>
            </Alert>
          );
          const timer = () =>
            setTimeout(() => {
              setMessageError("");
            }, 3000);
          setTimerId(timer);
        });
    },
  });

  let enteredmask = "";

  function addNumber(num) {
    let oldnum = entered;
    let newnum = oldnum.concat(num);
    setEntered(newnum);
  }

  const SqlDatetime = () => {
    const time = new Date();
    const dateWithOffest = new Date(time.getTime() - time.getTimezoneOffset() * 60000);
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  function checkNumber() {
    setEnabled(false);
    setMessageError("");
    setMessageSuccess("");
    clearTimeout(timerId);
    if (entered === empid.toString()) {
      mutation.mutate();
    } else {
      setMessageError(
        <Alert severity="error" style={{ justifyContent: "center" }}>
          <strong>Incorrect PIN, Please try again!</strong>
        </Alert>
      );
      const timer = () =>
        setTimeout(() => {
          setMessageError("");
          setEntered("");
          setEnabled(true);
        }, 3000);
      setTimerId(timer);
    }
  }

  if (entered.length > 0) {
    for (var i = 0; i < entered.length; i++) {
      if (i < 8) {
        enteredmask = enteredmask.concat("*");
      }
    }
  }

  return (
    <div>
      <div className={classes.pin}>{enteredmask}</div>
      <hr />
      <div className={classes.pinMessage}>
        {messageSuccess}
        {messageError}
        {mutation.isLoading && (
          <Fade in style={{ textAlign: "center" }}>
            <div>
              <CircularProgress />
            </div>
          </Fade>
        )}
      </div>
      <Grid container align="center" justifyContent="center" spacing={1}>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("1")}>
          1
        </Grid>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("2")}>
          2
        </Grid>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("3")}>
          3
        </Grid>
      </Grid>
      <Grid container align="center" justifyContent="center" spacing={1}>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("4")}>
          4
        </Grid>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("5")}>
          5
        </Grid>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("6")}>
          6
        </Grid>
      </Grid>
      <Grid container align="center" justifyContent="center" spacing={1}>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("7")}>
          7
        </Grid>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("8")}>
          8
        </Grid>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("9")}>
          9
        </Grid>
      </Grid>
      <Grid container align="center" justifyContent="center" spacing={1}>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && setEntered("")}>
          CLEAR
        </Grid>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && addNumber("0")}>
          0
        </Grid>
        <Grid item xs={4} className={enabled ? classes.gridItem : classes.gridItemDisabled} onClick={() => enabled && checkNumber()}>
          ENTER
        </Grid>
      </Grid>
    </div>
  );
};

export default CheckPin;
